
import api from '@/api/api';
import Grid from '@/components/Grid.vue';
import ModalCrud from '@/components/ModalCrud.vue';
import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Departamentos',
	data() {
		return {
			loadingGrid: false,
			valid: true,
			isLoading: false,
			textoModal: '',
			modalAberto: false,
			departamentos: [],
			descricaoRules: [
				v => !!v || 'Descrição precisa ser preenchida.',
			],
			departamento: {
				id: 0,
				descricao: '',
				codigo_status: false
			},
			columns: [
				{
					text: 'ID',
					align: 'start',
					sortable: true,
					value: 'ID',
				},
				{
					text: 'Descrição',
					sortable: true,
					value: 'DESCRICAO',
				},
				{
					text: 'Status',
					sortable: true,
					value: 'STATUS',
				},
				{
					text: 'Ações',
					value: 'actions',
					sortable: false
				}
			],
		}
	},
	components: {
		Grid,
		ModalCrud
	},
	methods: {
		newDepartment() {
			this.modalAberto = true;
			this.departamento.id = 0;
			this.departamento.descricao = '';
			this.departamento.codigo_status = true;
			this.textoModal = 'Novo Departamento';
		},
		async editDepartment(item) {
			this.textoModal = 'Editar Departamento';
			const response = await api.get(`/department/${item.ID}`);
			const { CODIGOSETOR, DESCRICAOSETOR, CODIGO_STATUS } = response.data;
			this.departamento.id = CODIGOSETOR;
			this.departamento.descricao = DESCRICAOSETOR;
			this.departamento.codigo_status = CODIGO_STATUS === 'A' ? true : false;
			this.modalAberto = true;
		},
		async saveDepartment() {
			try {
				if (this.$refs.form.validate()) {
					this.isLoading = true;
					const [metodo, mensagem] = this.departamento.id ? ['put', 'alterado'] : ['post', 'criado'];
					await api[metodo]('/department', this.departamento);
					const response = await api.get('/department');
					this.departamentos = response.data;
					this.$toast.success(`Departamento ${mensagem} com sucesso!`);
					this.modalAberto = false
				} else {
					this.$toast.warning('Formulário inválido! Verifique os dados.');
				}
			} catch (err) {
				this.$toast.error('Falha ao comunicar com o banco de dados!');
			}
			finally {
				this.isLoading = false;
			}
		}
	},
	async mounted() {
		this.loadingGrid = true;
		if (this.departamentos.length === 0) {
			const response = await api.get("/department");
			this.departamentos = response.data;
		}
		store.commit(M_MENU, "Departamentos");
		this.loadingGrid = false;
	},
})

