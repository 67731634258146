export const columns = {
    cadastros: {
        usuarios: [
            {
                text: 'ID',
                align: 'start',
                sortable: true,
                value: 'ID',
            },
            {
                text: 'Nome',
                sortable: true,
                value: 'NOMECOMPLETOUSUARIO',
            },
            {
                text: 'E-mail',
                sortable: true,
                value: 'EMAILUSER',
            },
			{
				text: 'Departamento',
                sortable: true,
                value: 'DESCRICAOSETOR',
			},
            {
                text: 'Supervisor',
                sortable: false,
                value: 'SUPERVISOR'
            },
            {
                text: 'Status',
                sortable: true,
                value: 'CODIGO_STATUS',
            },
            {
                text: 'Ações',
                value: 'actions',
                sortable: false
            }
        ]
    },
    lancamentos: {
        trackProjetos: [
            {
                text: 'ID',
                align: 'start',
                sortable: true,
                value: 'id',
            },
            {
                text: 'Tipo',
                sortable: true,
                value: 'tipoProjeto',
            },
            {
                text: 'Descrição',
                sortable: true,
                value: 'descricao',
            },
            {
                text: 'Status',
                sortable: true,
                value: 'statusTarefa',
            },
            {
                text: 'Prioridade',
                sortable: true,
                value: 'prioridade',
            },
            {
                text: 'Progresso %',
                sortable: true,
                value: 'progresso',
            },
            {
                text: 'Tarefa Redmine',
                sortable: true,
                value: 'idTarefaRedmine',
            },
            {
                text: 'Observação',
                sortable: true,
                value: 'observacao',
            },
            {
                text: 'Data Inicial',
                sortable: true,
                value: 'dataIni',
            },
            {
                text: 'Data Final',
                sortable: true,
                value: 'dataFim',
            },
            {
                text: 'Status',
                sortable: true,
                value: 'statusProjeto',
            },
            {
                text: 'Ações',
                value: 'actions',
                sortable: false
            }
        ],
        track: [
            {
                text: "ID",
                align: "start",
                sortable: true,
                value: "ID",
                width: '2%'
            },
            {
                text: "Descrição",
                sortable: true,
                value: "DESCRICAO",
                cellClass: "descriptionColumn text-truncate"
            },
            {
                text: "Atividade",
                sortable: true,
                value: "ACTIVITY",
            },
            {
                text: "Módulo",
                sortable: true,
                value: "MODULE",
            },
            {
                text: "Categoria",
                sortable: true,
                value: "CATEGORY",
            },
            {
                text: "Tag",
                sortable: true,
                value: "TAG",
            },
            {
                text: "Início",
                sortable: true,
                value: "HORAINICIO",
            },
            {
                text: "Fim",
                sortable: true,
                value: "HORAFIM",
            },
            {
                text: "Total",
                sortable: true,
                value: "TOTAL",
            },
            {
                text: "Ações",
                value: "actions",
                sortable: false
            }
        ],
        nfseExpress: [
            {
                text: "ID",
                align: "start",
                sortable: true,
                value: "ID_REF_PARCELA_CONTA",
                width: '2%'
            },
            {
                text: "ID Conta",
                sortable: true,
                value: "ID_REF_CONTA",
                cellClass: "descriptionColumn text-truncate",
                align: "start"
            },
            {
                text: "Cliente",
                sortable: true,
                value: "CLIENTE",
                align: "left",
            },             
            {
                text: "N. RPS",
                sortable: true,
                value: "NUMERORPS",
                align: "right",  
            },  
            {
                text: "N. NFS-e",
                sortable: true,
                value: "NUMERONFSE",
                align: "right",  
            },       
			{
				text: "E-mail",
				sortable: true,
				value: "emailState",
				align: "start",
				width: '150px' 
			}, 
            {
                text: "Serviço",
                sortable: true,
                value: "DISCRIMINACAO",
                align: "start",
                cellClass: "descriptionColumn text-truncate"
            },
            {
                text: "Valor Líquido",
                sortable: true,
                align: "left",
                value: "VALOR_LIQUIDO",
            },
            {
                text: "Ações",
                value: "actions",
                sortable: false
            }
        ],
        nfseChecks: [
            {
                text: "ID",
                align: "start",
                sortable: true,
                value: "ID_REF_PARCELA_CONTA",
                width: '2%'
            },
            {
                text: "ID Empresa",
                sortable: true,
                value: "ID_REF_EMPRESA",    
                width: '1%',            
                align: "start"
            },
            {
                text: "Prestador",
                sortable: true,
                value: "RAZAO_PRESTADOR",                
                cellClass: "descriptionColumn text-truncate"
            },            
            {
                text: "Tomador",
                sortable: true,
                value: "TOMADOR",                
                cellClass: "descriptionColumn text-truncate"
            },                       
            {
                text: "ID Contas Receber",
                sortable: true,
                value: "ID_REF_CONTA",                
                width: '1%'
            },           
            {
                text: "Serviço",
                sortable: true,
                value: "DISCRIMINACAO",
                align: "start",
                cellClass: "descriptionColumn text-truncate"
            },
            {
                text: "Valor Bruto",
                sortable: true,
                align: "start",
                value: "VALOR_BRUTO",
            },
            {
                text: "Taxa ISSQN",
                sortable: true,
                align: "end",
                value: "TAXA_ISSQN",
            },
            {
                text: "Valor ISSQN",
                sortable: true,
                align: "start",
                value: "VALOR_ISSQN",
            },            
            {
                text: "Valor Líquido",
                sortable: true,
                align: "start",
                value: "VALOR_LIQUIDO",
            },            
            {
                text: "Ações",
                value: "actions",
                sortable: false
            }         
        ],
        cargaDados: [
            {
                text: "ID",
                align: "start",
                sortable: true,
                value: "ID",
                width: '2%'
            },
            {
                text: "Consulta",
                sortable: true,
                value: "consulta"                
            }, 
            {
                text: "Período",
                sortable: true,
                value: "periodo"                
            },           
            {
                text: "Data Início",
                sortable: true,
                value: "dataInicio",                                
            },                       
            {
                text: "Hora Início",
                sortable: true,
                value: "horaInicio",                                
            },           
            {
                text: "Data Fim",
                sortable: true,
                value: "dataFim",                                
            },
            {
                text: "Hora Fim",
                sortable: true,
                value: "horaFim",                                
            },
            {
                text: "Tempo decorrido",
                sortable: true,                
                value: "tempoExecucao",
            },
            {
                text: "Solicitante",                
                sortable: true,
                value: "solicitante",
            },
            {
                text: 'Execução',
                sortable: false,
                value: "LOADING",
                align: 'center'
            },
            {
                text: 'Retornou Dados',
                sortable: false,
                value: "NO_DATA",
                align: 'center'
            }
        ],
		controleFinanceiro: [
			{
                text: "ID",
                align: "start",
                sortable: true,
                value: "id",
                width: '2%'
            },
			{
                text: "Responsável",
                sortable: true,
                value: "nomeUsuario"                
            }, 
			{
                text: "Saldo em Dinheiro",
                sortable: true,
                align: "start",
                value: "saldoDinheiro",
				width: '10%'
            },
			{
                text: "Saldo em Cartão",
                sortable: true,
                align: "start",
                value: "saldoCartao",
				width: '10%'
            },
			{
                text: "Status",
                sortable: true,
                align: "start",
                value: "idStatus",
				width: '5%'
            },
			{
                text: "Ações",
                value: "actions",
                sortable: false,
				width: '5%'
            } 
		]

    }
}